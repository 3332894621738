import { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import Footer from "../../includes/footer-tab";
import Preloader from "../Preloader";
import BackButton from "../../includes/Back_btn";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate, Link } from "react-router-dom";

function Withdrawal() {
  const navigate = useNavigate();
  const { isAuthenticated, profile, WithdrawMoney } = useContext(AuthContext);
  const [preloader, setpreloader] = useState(true);
  const [Balance, setBalance] = useState("Loading..");
  const [Password, setPassword] = useState("");
  const [Amount, setAmount] = useState("");
  const [BankCard, setBankCard] = useState("");
  const [Minimum, setMinimum] = useState("Loading...");
  if (!isAuthenticated) {
    navigate("/login");
  }
  const message = (status, text) => {
    if (status === 1) {
      toast.success(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (status === 0) {
      toast.error(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (status === 2) {
      toast.warning(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const handleRecharge = () => {
    if (Amount === "") {
      message(0, "Please enter Amount");
    } else if (Password === "") {
      message(0, "Please select Payment Mode");
    } else if (BankCard === "") {
      message(0, "Please select Payment Mode");
    } else if (Password === "") {
      message(0, "Please Enter Payment Mobile Number");
    } else if (Amount > Balance) {
      message(0, "Insufficient Wallet balance");
    } else if (Amount < Minimum) {
      message(0, "Please enter amount greater than " + Minimum);
    } else {
      setpreloader(true);
      WithdrawMoney(Amount, Password, BankCard, (data) => {
        setpreloader(false);
        if (data.error) {
          message(0, data.message);
        } else {
          message(1, data.message);
          setTimeout(() => {
            navigate("/mine");
          }, 1000);
        }
      });
    }
  };
  const inputAmount = (event) => {
    let amount = parseInt(event.target.value);
    setAmount(amount);
  };
  useEffect(() => {
    profile((data) => {
      setpreloader(false);
      if (data.error) {
        message(0, data.message);
      } else {
        setBalance(data.data[0].wallet);
        setMinimum(data.data[0].setting.withdrawal_amount);
      }
    });
  }, [profile]);

  return (
    <>
      <Preloader status={preloader} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <motion.div
        initial={{ translateX: -100 }}
        animate={{ translateX: 0 }}
        exit={{ translateX: 0 }}
        transition={{ duration: 0.2 }}
      >
        <div className="login header">
          <div className="item">
            <BackButton />
            <p>Withdrawal</p>
            <Link to="/history/withdrawal" className="historyright">
              {/* <AiOutlineHistory /> */}
            </Link>
          </div>
        </div>
        <div className="recharge recharge_box">
          <div className="bonus-tag">Balance:₹ {Balance}</div>
          {/* <div className="WithdrawalBox">
            <img
              src="https://i.pinimg.com/564x/07/a3/6f/07a36f8150f730182b06489a34b690e6.jpg"
              alt=""
            />
            <input type="radio" name="PAYMENTMETHOD" value="Bank" onClick={(event) => {
                setBankCard("Bank");
              }}/>
          </div> */}
          <div className="WithdrawalBox">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR363BO6LXUKGXXHiOTaHnqqDXgwpw4qOeOoA&s"
              alt=""
            />
            <input type="radio" name="PAYMENTMETHOD" value="Paytm" onClick={(event) => {
                setBankCard("Paytm");
              }}/>
          </div>
          <div className="WithdrawalBox">
            <img
              style={{width:"135px"}}
              src="https://cdn.worldvectorlogo.com/logos/phonepe-1.svg"
              alt=""
            />
            <input type="radio" name="PAYMENTMETHOD" value="Phonepe" onClick={(event) => {
                setBankCard("Phonepe");
              }}/>
          </div>
          <div className="WithdrawalBox">
            <img
              style={{width:"135px"}}
              src="https://cdn.worldvectorlogo.com/logos/google-pay-2.svg"
              alt=""
            />
            <input type="radio" name="PAYMENTMETHOD" value="GPay" onClick={(event) => {
                setBankCard("GPay");
              }}/>
          </div>
          {/* <div className="input_box">
            <img src="/icons_asset/card.png" alt="" />
            <select
              onChange={(event) => {
                setBankCard(event.target.value);
              }}
            >
              <option value="">Select Payment Mode</option>
              <option value="Phonepe">Phonepe</option>
              <option value="GPay">GPay</option>
              <option value="Paytm">Paytm</option>
            </select>
          </div> */}
          <label class="custom-field one">
            <input type="tel"
              onInput={(event) => {
                setPassword(event.target.value);
              }}
              value={Password}
              placeholder="&nbsp;"/>
            <span class="placeholder">Enter Mobile Number</span>
            <span class="border"></span>
          </label>
          <label class="custom-field one">
            <input type="tel"
              onInput={(event) => {
                inputAmount(event);
              }}
              value={Amount}
              placeholder="&nbsp;"/>
            <span class="placeholder">Enter Amount</span>
            <span class="border"></span>
          </label>
          <p className="">
            <b>Note:</b> Minimum Withdrawal amount is ₹{Minimum}
          </p>
          <div className="input_box_btn">
            <button className="login_btn ripple" onClick={handleRecharge}>
              Withdrawal
            </button>
          </div>
        </div>
      </motion.div>
      <Footer page="recharge" />
    </>
  );
}

export default Withdrawal;
